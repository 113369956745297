<template>
  <router-link
    :to="{ name: 'equipment', params: { slug: equipment.slug } }"
    class="w-full"
  >
    <article class="overflow-hidden rounded-lg border-2 border-primary">
      <!-- 
        under="var(--color-primary-half)"
        over="linear-gradient(to bottom, transparent, transparent, var(--color-primary))" 
      -->
      <tw-box :ratio="4/3">
        <BgImage
          class="w-full h-full bg-center bg-no-repeat bg-contain flex flex-col p-3 overflow-hidden"
          :empty="require('@/assets/full-logo.svg')"
          under="#ffffff"          
          over="linear-gradient(to bottom, transparent, transparent, rgba(255, 131, 0, 0.21),#ff8300)"
          :src="equipment?.thumbnail"
        >
          <div class="mt-auto flex justify-between">
            <span class="text-white uppercase leading-none">
              {{ equipment.name }}
            </span>
            <!-- <button
              class="btn btn-sm btn-icon btn-white-primary relative"
              @click="addEquipmentToCart()"
            >
              <r-icon type="cart" class="h-4 absolute icon-center" />
            </button> -->
          </div>
        </BgImage>
      </tw-box>

      <template v-if="rates.length">
        <div class="h-28 flex flex-col justify-between p-3 font-semibold text-secondary">
          <div
            v-for="rate in rates"
            :key="`${equipment.slug}-${rate.name}-rate`"
            class="flex justify-between"
          >
            <span class="uppercase">{{ rate.name }}</span>
            <span>{{ currency(rate.price) }}</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="h-28 flex flex-col justify-center items-center">
          <div>
            No hay disponible.
          </div>
        </div>
      </template>
    </article>
  </router-link>
</template>

<script>
import { currency } from '@/filters';
// import { addToCart } from '@/store/shoppingCart';

export default {
  props: {
    equipment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    rates() {
      return this.equipment?.rates?.filter?.(rate => rate.name) || [];
    },
  },
  methods: {
    // addEquipmentToCart() {
    //   addToCart({
    //     id: this.equipment.id,
    //     name: this.equipment.name,
    //     model: this.equipment.model,
    //     slug: this.equipment.slug,
    //     rates: this.equipment.rates,
    //     count: 1,
    //   });
    // },
    currency,
  },
};
</script>

<style></style>

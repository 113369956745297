<template>
  <div>
    <LocationDates @change="updateItems" />
    <div class="border-t my-5">
      <ItemDetails
        v-for="(item, $index) in equipment.items"
        :key="$index"
        :item="item"
        class=" hover:bg-gray-100 my-3 pb-3"
        actions-add-cart
        :update-item-date="updateItemDate"
        :remove-from-cart="removeFromCart"
        :add-item-to-cart="addItemToCart"
        :is-in-cart="isInCart"
        @updateAvailableByDate="updateAvailableByDate"
        @openDetails="openDetails"
      />
    </div>
    <modal-component ref="modal" modal-class="w-100">
      <h1 class="text-secondary font-semibold font-secondary">{{ equipment.name }}</h1>
      <div class="w-full">
        <!-- <tw-box :ratio="4/3" class="mx-1"> -->
        <Gallery
          :images="itemDetails.gallery"                        
        />
        <!-- </tw-box> -->
      </div>
    </modal-component>
  </div>
</template>

<script>
import Gallery from '@/components/UI/Gallery.vue';
import LocationDates from '@/components/inc/inputs/LocationDates';
import ModalComponent from '@/components/inc/ModalComponent';
import ItemDetails from '@/components/Operations/ItemDetails.vue';
import { addToCartCustomDate, isInCart, useLocation, itemIsAvailable, removeFromCart, itemIsAvailableCustomDate } from '@/store/shoppingCart';

export default {
  components: {
    ItemDetails,
    Gallery,
    LocationDates,
    ModalComponent,
  },
  props: {
    equipment: {
      required: true,
      type: Object,
    },
  },
  emits: ['change'],
  setup() {
    const location = useLocation();
    return {isInCart, removeFromCart, location};
  },
  data: () => ({
    itemDetails: {
      gallery: [],
    },
    updateItemDate: false,
  }),
  methods: {
    async addItemToCart(item, date) {
      if(!this.location) {
        alert('Ingresa la ubicacion de trabajo');
      }
      const available = await itemIsAvailable(item);
      if(available) {
        addToCartCustomDate(item, date.startDate, date.endDate);
      } else {
        alert('Item is not Available');
      }
    },
    async updateItems() {
      this.updateItemDate = true;
      this.$emit('change');
      this.$nextTick(() => { this.updateItemDate = false; });
    },
    openDetails(itemId) {
      this.itemDetails = itemId;
      this.$refs.modal.open();
    },
    closeDetails() {
      this.$refs.modal.close();
    },
    async updateAvailableByDate($event) {
      const available = await itemIsAvailableCustomDate($event.itemId, $event.startDate, $event.endDate);
      const item = this.equipment.items.find(item => item.id === $event.itemId);
      if (item) {
        item.available = available;
      }
    },
  },
};
</script>

<style>
</style>


import EquipmentCard from '@/components/Equipment/Card.vue';
import EquipmentAvailable from '@/components/Equipment/Available.vue';
// import GallerySlider from '@/components/GallerySlider.vue';
import Gallery from '@/components/UI/Gallery.vue';
import { currency } from '@/filters';
import { refStartDate, refEndDate } from '@/store/shoppingCart';
import { getEquipment, getItemsEquipmentByDates, refEquipment } from '@/store/equipment';
import { Category } from '@/store/models/categories';
import { computed, defineComponent, toRef, watch } from 'vue';

export default defineComponent({
  components: {
    EquipmentCard,
    // GallerySlider,
    EquipmentAvailable,
    Gallery,
  },
  props: {
    slug: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const slug = toRef(props, 'slug');
    const equipment = refEquipment();
    const category = computed<Category|null>(() => equipment.value?.categories?.[0] || null);
    const subcategory = computed<any>(() => equipment.value?.subcategories?.[0] || null);
    const updateItems = async() => {
      getItemsEquipmentByDates({
        equipment: equipment.value?.slug,
        startDate: refStartDate(),
        endDate: refEndDate(),
      });
    };
    watch(slug, () => getEquipment(slug.value));
    return { category, currency, equipment, subcategory, updateItems };
  },
});

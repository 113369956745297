<template>
  <div v-if="display" class="fixed inset-0 z-50 grid">
    <div
      class="bg-black opacity-25 absolute inset-0 -z-10"
      @click="close()"
    ></div>
    <div class="bg-white place-self-center p-10 rounded relative" :class="modalClass">
      <button class="absolute top-0 right-0" @click="close()">
        <r-icon type="times" class="h-6 mt-3 mr-3" />
      </button>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['modalClass'],
  data: () => ({
    display: false,
  }),
  methods: {
    close() {
      this.display = false;
    },
    open() {
      this.display = true;
    },
  },
};
</script>

<style></style>

<template>
  <div class="grid grid-cols-a:1 md:grid-cols-a:1:a:a gap-3">
    <template v-if="!excludeLocation">
      <span class="flex items-center">
        Ubicacion (Municipio):
      </span>
      <div class="flex items-center w-full">
        <PlacesInput class="w-1/2" />
      </div>
    </template>
    <template v-if="!excludeDate">
      <div class="flex align-top md:items-center">
        Fechas:
      </div>
      <div class="md:flex items-center">
        <Datepicker
          v-model="startDate"
          class="rounded-md"
          :class="{'bg-gray-200': disabled}"
          :disabled="disabled"
          :mindate="minStart"
          :maxdate="maxEnd"
          @change="emitDate"
        />
        <label class="md:visible invisible">-</label>
        <Datepicker
          v-model="endDate"
          class="rounded-md"
          :class="{'bg-gray-200': disabled}"
          :disabled="disabled"
          :mindate="startDate"
          :maxdate="maxEnd"
          @change="emitDate"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { dateToISO } from '@/filters';
import { refEndDate, refStartDate, useTomorrow } from '@/store/shoppingCart';
import PlacesInput from './AlgoliaPlaces';
import Datepicker from '@/components/UI/Datepicker';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LocationDates',
  components: {
    PlacesInput,
    Datepicker,
  },
  props: {
    disabled: Boolean,
    excludeLocation: Boolean,
    excludeDate: Boolean,
    initDate: Object,
  },
  emits: ['change'],
  setup() {
    const minStart = dateToISO(useTomorrow());
    return {minStart};
  },
  data () {
    return {
      startDate: dateToISO(new Date),
      endDate: dateToISO(new Date),
      maxEnd: dateToISO((() => {
        const date = new Date();
        date.setMonth(date.getMonth() + 7);
        date.setDate(0);
        return date;
      })()),
    };
  },
  watch: {
    initDate: {
      handler () {
        this.setInitDates();
      },
      deep: true,
    },
    startDate () {
      this.emitDate();
    },
    endDate () {
      this.emitDate();
    },
  },
  created () {
    this.setInitDates();
  },
  methods: {
    emitDate () {
      this.$emit('change', this.startDate, this.endDate);
    },
    setInitDates () {
      this.startDate = !this.initDate ? refStartDate() : dateToISO(this.initDate.startDateInit);
      this.endDate = !this.initDate ? refEndDate() : dateToISO(this.initDate.endDateInit);
    },
  },
});
</script>

<style></style>


import { Moveout } from '@/directives/Moveout';
import { defineComponent, PropType } from 'vue';
import { formattedDate } from '@/filters';
import { Data } from '@/types';

interface DatepickerData {
  month_names: string[]
  days: string[]
  showDatepicker: boolean
  datepickerValue: string | undefined
  month: number | null | undefined
  year: number | null | undefined
  no_of_days: number[]
  blankdays: number[]
}

export default defineComponent({
  name: 'Datepicker',
  directives: { Moveout },
  props: {
    disabled: Boolean,
    modelValue: {
      type: String as PropType<String>,
    },
    mindate: {
      type: String as PropType<String>,
      default: null,
    },
    maxdate: {
      type: String as PropType<String>,
      default: null,
    },
  },
  emits: ['update:modelValue',  'mindate', 'maxdate'],
  setup() {
    return {formattedDate};
  },
  data () {
    return {
      month_names: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      days: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
      showDatepicker: false,
      datepickerValue: '',
      month: null,
      year: null,
      no_of_days: [],
      blankdays: [],
    } as DatepickerData;
  },
  watch: {
    'mindate'(val) {
      const selectDate = new Date(val);
      selectDate.setDate(selectDate.getDate() + 1);
    },
    'modelValue'() {
      const dateModel = this.modelValue ? new Date(this.modelValue as string) : null;
      this.month = dateModel?.getMonth();
      this.year = dateModel?.getFullYear();
    },
  },
  mounted() {
    this.initDate();
    this.getNoOfDays();
  },
  methods: {
    initDate() {
      const today = new Date();
      // this.datepickerValue = new Date(this.year, this.month, today.getDate()).toDateString();
      const dateModel = this.modelValue ? new Date(this.modelValue as string) : null;
      const currentDate = dateModel ? dateModel.setDate(dateModel.getDate() + 1) : today;
      this.month = dateModel ? dateModel.getMonth() : today.getMonth();
      this.year = dateModel ? dateModel.getFullYear() : today.getFullYear();
      this.datepickerValue = this.formattedDate(currentDate);
    },
    isToday(date:number) {
      if(typeof this.month !== 'number' || typeof this.year !== 'number')
      {
        return;
      }
      const today = new Date();
      const d = new Date(this.year, this.month, date);
      return today.toDateString() === d.toDateString() ? true : false;
    },
    isMinDate(date:number) {
      if(typeof this.month !== 'number' || typeof this.year !== 'number')
      {
        return;
      }
      const d = new Date(this.year, this.month, date);
      const min = new Date(this.mindate as string);
      return this.mindate === null ? false : min > d ? true : false;
    },
    isSelected(date:number) {
      if(typeof this.month !== 'number' || typeof this.year !== 'number')
      {
        return;
      }
      const selectDate = new Date(this.modelValue as string);
      selectDate.setDate(selectDate.getDate() + 1);
      const d = new Date(this.year, this.month, date);
      return selectDate.toDateString() === d.toDateString() ? true : false;
    },
    isMaxDate(date:number) {
      if(typeof this.month !== 'number' || typeof this.year !== 'number')
      {
        return;
      }
      const d = new Date(this.year, this.month, date - 1 );
      const max = new Date(this.maxdate as string);
      return this.maxdate === null ? false : max < d ? true : false;
    },
    getDateValue(date:number) {
      if(typeof this.month !== 'number' || typeof this.year !== 'number')
      {
        return;
      }
      const selectedDate = new Date(this.year, this.month, date);
      const formatDate = selectedDate.getFullYear() + '-' + ('0'+ (selectedDate.getMonth() + 1)).slice(-2) + '-' + ('0' + selectedDate.getDate()).slice(-2);
      // this.datepickerValue = selectedDate.toDateString();
      this.datepickerValue = this.formattedDate(selectedDate);
      (this.$refs.date as Data).value = formatDate;
      this.$emit('update:modelValue', formatDate);
      this.showDatepicker = false;
    },
    getNoOfDays() {
      if(typeof this.month !== 'number' || typeof this.year !== 'number')
      {
        return;
      }
      const daysInMonth = new Date(this.year, this.month + 1, 0).getDate();
      // find where to start calendar day of week
      const dayOfWeek = new Date(this.year, this.month).getDay();
      const blankdaysArray = [];
      for ( let i=1; i <= dayOfWeek; i++) {
        blankdaysArray.push(i);
      }
      const daysArray = [];
      for ( let i=1; i <= daysInMonth; i++) {
        daysArray.push(i);
      }
      this.blankdays = blankdaysArray;
      this.no_of_days = daysArray;
    },
    moveMonth(month:number) {
      if(typeof this.month !== 'number' || typeof this.year !== 'number')
      {
        return;
      }
      this.month = this.month + month;
      if(this.month === 12) {
        this.month = 0;
        this.year++;
      }
      if(this.month === -1) {
        this.month = 11;
        this.year--;
      }
    },
    clickedOutside() {
      this.showDatepicker = false;
    },
  },
});

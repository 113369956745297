<template>
  <div v-moveout="clickedOutside" class="relative">
    <input ref="date" type="hidden" name="date" />
    <div
      class="border rounded-md py-3 px-3 text-gray-600 placeholder-gray-700 text-sm tracking-tighter leading-tight cursor-pointer focus:outline-none focus:shadow-outline"
      @click="!disabled ? (showDatepicker = !showDatepicker) : ''"
    >
      <span class="flex mr-8 items-center">
        <span class="block truncate text-gray-600 capitalize">
          {{ formattedDate(modelValue, spaced = true) }}
        </span>
      </span>
      <span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
        <svg class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg>
      </span>
    </div>
    <div
      v-show="showDatepicker"
      style="width: 17rem"
      class="border z-50 bg-white mt-8 rounded p-2 absolute top-4 left-0"
    >
      <div class="flex justify-between items-center mb-2">
        <div>
          <span class="text-base font-bold text-gray-700">{{ month_names[month] }}</span>
          <span class="ml-1 text-base text-gray-600 font-normal">{{ year }}</span>
        </div>
        <div>
          <button
            type="button"
            class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
            :disabled="year <= new Date().getFullYear() & month == 0 ? true : false"
            :class="{'cursor-not-allowed opacity-25': year <= new Date().getFullYear() & month == 0 }"
            @click="moveMonth(-1); getNoOfDays()"
          >
            <svg class="h-4 w-4 text-gray-500 inline-flex" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button
            type="button"
            class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
            @click="moveMonth(1); getNoOfDays()"
          >
            <svg class="h-4 w-4 text-gray-500 inline-flex" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>
      <div class="flex flex-wrap mb-3 -mx-1">
        <template v-for="(day, index) in days" :key="index">
          <div style="width: 14.26%" class="px-1">
            <div class="text-gray-800 font-medium text-center text-xs">{{ day }}</div>
          </div>
        </template>
      </div>
      <div class="flex flex-wrap -mx-1">
        <template v-for="(blankday, index) in blankdays" :key="index">
          <div
            style="width: 14.28%"
            class="text-center border p-1 border-transparent text-sm"
          ></div>
        </template>
        <template v-for="(date, dateIndex) in no_of_days" :key="dateIndex">
          <button
            style="width: 14.28%"
            type="button"
            :disabled="isMinDate(date) || isMaxDate(date)"
            class="mb-1 text-center text-sm leading-none rounded-full leading-loose transition ease-in-out duration-100"
            :class="{'cursor-not-allowed opacity-25': isMinDate(date) == true || isMaxDate(date) == true, 'bg-blue-500 text-white': isSelected(date) === true,'hover:bg-blue-200': isMinDate(date) == false || isMaxDate(date) == false, 'bg-blue-200 text-white': isToday(date) == true}"
            @click="getDateValue(date)"
          >
            {{ date }}
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Moveout } from '@/directives/Moveout';
import { defineComponent, PropType } from 'vue';
import { formattedDate } from '@/filters';
import { Data } from '@/types';

interface DatepickerData {
  month_names: string[]
  days: string[]
  showDatepicker: boolean
  datepickerValue: string | undefined
  month: number | null | undefined
  year: number | null | undefined
  no_of_days: number[]
  blankdays: number[]
}

export default defineComponent({
  name: 'Datepicker',
  directives: { Moveout },
  props: {
    disabled: Boolean,
    modelValue: {
      type: String as PropType<String>,
    },
    mindate: {
      type: String as PropType<String>,
      default: null,
    },
    maxdate: {
      type: String as PropType<String>,
      default: null,
    },
  },
  emits: ['update:modelValue',  'mindate', 'maxdate'],
  setup() {
    return {formattedDate};
  },
  data () {
    return {
      month_names: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      days: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
      showDatepicker: false,
      datepickerValue: '',
      month: null,
      year: null,
      no_of_days: [],
      blankdays: [],
    } as DatepickerData;
  },
  watch: {
    'mindate'(val) {
      const selectDate = new Date(val);
      selectDate.setDate(selectDate.getDate() + 1);
    },
    'modelValue'() {
      const dateModel = this.modelValue ? new Date(this.modelValue as string) : null;
      this.month = dateModel?.getMonth();
      this.year = dateModel?.getFullYear();
    },
  },
  mounted() {
    this.initDate();
    this.getNoOfDays();
  },
  methods: {
    initDate() {
      const today = new Date();
      // this.datepickerValue = new Date(this.year, this.month, today.getDate()).toDateString();
      const dateModel = this.modelValue ? new Date(this.modelValue as string) : null;
      const currentDate = dateModel ? dateModel.setDate(dateModel.getDate() + 1) : today;
      this.month = dateModel ? dateModel.getMonth() : today.getMonth();
      this.year = dateModel ? dateModel.getFullYear() : today.getFullYear();
      this.datepickerValue = this.formattedDate(currentDate);
    },
    isToday(date:number) {
      if(typeof this.month !== 'number' || typeof this.year !== 'number')
      {
        return;
      }
      const today = new Date();
      const d = new Date(this.year, this.month, date);
      return today.toDateString() === d.toDateString() ? true : false;
    },
    isMinDate(date:number) {
      if(typeof this.month !== 'number' || typeof this.year !== 'number')
      {
        return;
      }
      const d = new Date(this.year, this.month, date);
      const min = new Date(this.mindate as string);
      return this.mindate === null ? false : min > d ? true : false;
    },
    isSelected(date:number) {
      if(typeof this.month !== 'number' || typeof this.year !== 'number')
      {
        return;
      }
      const selectDate = new Date(this.modelValue as string);
      selectDate.setDate(selectDate.getDate() + 1);
      const d = new Date(this.year, this.month, date);
      return selectDate.toDateString() === d.toDateString() ? true : false;
    },
    isMaxDate(date:number) {
      if(typeof this.month !== 'number' || typeof this.year !== 'number')
      {
        return;
      }
      const d = new Date(this.year, this.month, date - 1 );
      const max = new Date(this.maxdate as string);
      return this.maxdate === null ? false : max < d ? true : false;
    },
    getDateValue(date:number) {
      if(typeof this.month !== 'number' || typeof this.year !== 'number')
      {
        return;
      }
      const selectedDate = new Date(this.year, this.month, date);
      const formatDate = selectedDate.getFullYear() + '-' + ('0'+ (selectedDate.getMonth() + 1)).slice(-2) + '-' + ('0' + selectedDate.getDate()).slice(-2);
      // this.datepickerValue = selectedDate.toDateString();
      this.datepickerValue = this.formattedDate(selectedDate);
      (this.$refs.date as Data).value = formatDate;
      this.$emit('update:modelValue', formatDate);
      this.showDatepicker = false;
    },
    getNoOfDays() {
      if(typeof this.month !== 'number' || typeof this.year !== 'number')
      {
        return;
      }
      const daysInMonth = new Date(this.year, this.month + 1, 0).getDate();
      // find where to start calendar day of week
      const dayOfWeek = new Date(this.year, this.month).getDay();
      const blankdaysArray = [];
      for ( let i=1; i <= dayOfWeek; i++) {
        blankdaysArray.push(i);
      }
      const daysArray = [];
      for ( let i=1; i <= daysInMonth; i++) {
        daysArray.push(i);
      }
      this.blankdays = blankdaysArray;
      this.no_of_days = daysArray;
    },
    moveMonth(month:number) {
      if(typeof this.month !== 'number' || typeof this.year !== 'number')
      {
        return;
      }
      this.month = this.month + month;
      if(this.month === 12) {
        this.month = 0;
        this.year++;
      }
      if(this.month === -1) {
        this.month = 11;
        this.year--;
      }
    },
    clickedOutside() {
      this.showDatepicker = false;
    },
  },
});
</script>

<template>    
  <div     
    :style="`background-image: url(\'${getUrl}\'); background-size: 100%`"
    class="w-full h-96 shadow relative bg-center bg-no-repeat p-24 rounded-lg"
  >  
    <!-- Prev/Next Arrows -->
    <div class="absolute inset-0 flex">
      <div
        v-show="!images ? false : images.length > 1 ? true : false"
        class="flex cursor-pointer items-center justify-start w-1/3"
        @click="selectItem = selectItem === 0 ? (images.length - 1) : selectItem - 1"
      >
        <button           
          class="bg-gray-200 text-teal-500 focus:outline-none  hover:text-orange-500 font-bold hover:shadow rounded-full w-12 h-12 ml-4"
        >
          &#8592;
        </button>
      </div>
      <div
        class="flex cursor-pointer items-center justify-end"
        :class="!images ? 'w-full' : images.length > 1 ? 'w-1/3' : 'w-full'"
        @click="openModal()"
      >
      </div>
      <div
        v-show="!images ? false : images.length > 1 ? true : false"
        class="flex cursor-pointer items-center justify-end w-1/3"
        @click="selectItem = selectItem === (images.length - 1) ? 0 : selectItem + 1"
      >
        <button          
          class="bg-gray-200 text-teal-500 focus:outline-none hover:text-orange-500 font-bold hover:shadow rounded-full w-12 h-12 mr-4"
        >
          &#8594;
        </button>
      </div>
    </div>    
  </div>  
  <div>
    <modal-component ref="modal" modal-class="w-100">
      <tw-box :ratio="4/3" class="mx-1">
        <BgImage
          class="w-full h-full overflow-hidden rounded-lg border border-gray-100 bg-center bg-no-repeat bg-contain"           
          under="#ffffff"
          :src="getUrl"
        />
      </tw-box>
    </modal-component>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import ModalComponent from '@/components/inc/ModalComponent';
export default {
  name: 'Gallery',
  components: {
    ModalComponent,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    showmodal: {
      type: Boolean,
    },
  },  
  setup(props) {
    const selectItem = ref(0);    
    const logo = ref(require('@/assets/logo.svg'));
    const modal = ref(null);
    const getUrl = computed(() => {
      return props.images ? props.images.length > 0 ? props.images[selectItem.value]?.url : logo.value : logo.value;
    });
    function openModal() {
      if ((props.showmodal) & (!props.images ? false : props.images.length > 0)) {
        modal.value.open();
      }
    }
    return {
      selectItem,
      getUrl,
      openModal,
      modal,
    };
  },
};
</script>
